<template>
  <b-row class="lists page-my-cards">
    <b-col>
      <PageHeader :title="$t('general.my-subscription.title')" />

      <div class="post-list">
        <div class="page-my-cards__tabs">
          <CRadio
            v-model="selectedTab"
            v-for="(item, index) in tabs"
            :key="`${index + '-tab'}`"
            :name="item.name"
            :value="item"
            group-name="user-tab"
            class="user-tab"
          >
            <template #default="{ isActive, value: tab }">
              <div
                class="user-tab__item"
                :class="{ 'user-tab__item--active': isActive }"
              >
                <span class="user-tab__font"> {{ tab.content }} </span>
              </div>
            </template>
          </CRadio>
        </div>
      </div>

      <div class="post-list__posts">
        <InfinityScroll
          v-if="cardList.list && cardList.list.length"
          :items="cardList.list"
          :has-more="cardList.hasMoreCards"
          @rich-end="cardList.loadMore"
        >
          <template #default="{ item: card }">
            <SubscriptionCard :card="card" />
          </template>
        </InfinityScroll>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import PageHeader from "@/layout/PageHeader.vue";
import CRadio from "@/features/ui/CRadio.vue";
import InfinityScroll from "@/features/components/InfinityScroll.vue";

import { mapActions, mapGetters } from "vuex";
import { subscriptionTypes } from "@/config/subscriptionTypes";
import SubscriptionCard from "@/features/components/SubscriptionCard.vue";

export default {
  name: "PageMySubscription",
  components: {
    SubscriptionCard,
    InfinityScroll,
    CRadio,
    PageHeader,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      subscriptionTypes,
      selectedTab: {
        id: subscriptionTypes.active,
        content: this.$t("general.my-subscription.wallet"),
      },
    };
  },
  mounted() {
    this.initialLoadCards();
  },
  computed: {
    ...mapGetters({
      cards: "subscriptions/cards",
    }),
    cardList() {
      const cardsConfig = {
        [subscriptionTypes.active]: {
          list:
            // [  {
            //   id: 1,
            //   amount: 0,
            //   expires: null,
            //   active: true,
            //   info: null,
            //   created_at: "string",
            //   sub: {
            //     id: 1,
            //     name: "string",
            //     username: "string",
            //     role: 0,
            //     cover: null,
            //     avatar: null,
            //     price: 0,
            //     last_activity: null,
            //     allow_only_tip_msg: true,
            //     is_subscribed: true,
            //     is_followed: true,
            //     nsfw_enabled: true,
            //     stat: {
            //       fans: "1",
            //       followers: "1",
            //       views: "1",
            //       likes: "1",
            //       flows: "1",
            //       posts: "1",
            //       media: "1",
            //     },
            //   },
            // }] ||
            this.cards?.active?.data || [],
          hasMoreCards: Boolean(this.cards?.active?.next_page_url),
          loadMore: () =>
            this.loadActive({ page: this.cards?.active?.current_page + 1 }),
        },
        [subscriptionTypes.cancelled]: {
          list:
            // [
            //   {
            //     id: 1,
            //     amount: 0,
            //     expires: null,
            //     active: false,
            //     info: null,
            //     created_at: "string",
            //     sub: {
            //       id: 1,
            //       name: "string",
            //       username: "string",
            //       role: 0,
            //       cover: null,
            //       avatar: null,
            //       price: 0,
            //       last_activity: null,
            //       allow_only_tip_msg: true,
            //       is_subscribed: true,
            //       is_followed: true,
            //       nsfw_enabled: true,
            //       stat: {
            //         fans: "1",
            //         followers: "1",
            //         views: "1",
            //         likes: "1",
            //         flows: "1",
            //         posts: "1",
            //         media: "1",
            //       },
            //     },
            //   },
            // ] ||
            this.cards?.cancelled?.data || [],
          hasMoreCards: Boolean(this.cards?.cancelled?.next_page_url),
          loadMore: () =>
            this.loadCancelled({
              page: this.cards?.cancelled?.current_page + 1,
            }),
        },
      };

      return cardsConfig[this.selectedTab.id] || cardsConfig.active;
    },

    tabs() {
      return [
        {
          id: subscriptionTypes.active,
          content: this.$t("general.my-subscription.active"),
        },
        {
          id: subscriptionTypes.cancelled,
          content: this.$t("general.my-subscription.cancelled"),
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchSubscription: "subscriptions/fetchSubscription",
      fetchMoreSubscription: "subscriptions/fetchMoreSubscription",
    }),

    createQueryConfig(config) {
      return {
        page: this.cardList.current_page,
        limit: this.cardList.per_page,

        ...config,
      };
    },

    openAddCardPopup() {
      this.$popup.open("AddCardPopup");
    },

    loadActive() {
      if (this.requestInProgress) return;

      const queryConfig = this.createQueryConfig({
        page: this.cardList.current_page + 1,
        type: this.subscriptionTypes.active,
      });

      return this.waitRequest(() => {
        return this.fetchMoreSubscription(queryConfig).catch(this.checkErrors);
      });
    },

    loadCancelled() {
      if (this.requestInProgress) return;

      const queryConfig = this.createQueryConfig({
        page: this.cardList.current_page + 1,
        type: this.subscriptionTypes.cancelled,
      });

      return this.waitRequest(() => {
        return this.fetchMoreSubscription(queryConfig).catch(this.checkErrors);
      });
    },

    async initialLoadCards() {
      this.fetchSubscription()
        .then(() => {
          return this.fetchSubscription({ type: subscriptionTypes.cancelled })
            .then(() => {})
            .catch(this.checkErrors);
        })
        .catch(this.checkErrors)
        .finally(() => {
          this.$hideSpinner();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.lists {
  a {
    text-decoration: none;
    color: var(--dark);

    &:hover {
      color: var(--dark);
      text-decoration: none;
      background: var(--light);
    }
  }
}

.page-my-cards {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
  }

  &__search {
    margin-top: em(16);
  }

  &__tabs {
    display: flex;
    flex-wrap: nowrap;
  }

  &__group {
    margin: em(22) em(17);
  }
}

.collections-header-button {
  &__button {
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.user-tab {
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-7;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.post-list {
  margin-right: -15px;
  margin-left: -15px;

  &__posts {
    margin-top: 1px;
  }
}
</style>
