<template>
  <div class="subscription-card">
    <div class="subscription-card__header">
      <div class="subscription-card__card">
        <div class="subscription-card__image-wrapper">
          <img
            :src="avatarImage"
            :alt="`user avatar ${name}`"
            class="subscription-card__image"
          />
        </div>

        <div class="subscription-card__description">
          <div class="subscription-card__title">
            <span
              v-if="name"
              class="subscription-card__font subscription-card__font--title"
            >
              {{ name }}
            </span>

            <span
              v-if="username"
              class="subscription-card__font subscription-card__font--number"
            >
              {{ username }}
            </span>
          </div>

          <div class="subscription-card__info">
            <UiBadge v-if="info" class="subscription-card__badge">
              {{ info }}
            </UiBadge>

            <UiBadge
              v-if="info"
              @click.native="$router.push('/messages/' + userId)"
              class="subscription-card__badge"
              role="button"
            >
              <CIcon
                name="chat"
                path="subscriptions"
                class="subscription-card__icon"
              />{{ $t("general.my-subscription.chat") }}
            </UiBadge>
          </div>
        </div>
      </div>
    </div>

    <div class="subscription-card__button" v-if="!isActiveCard && !isUserFailedCard">
      <button @click="unsubscribe" class="card-button">
        <span class="card-button__font">
          {{ $t("general.my-subscription.subscribed") }}</span
        >
        <span class="card-button__font">
          {{ $t("general.my-subscription.for") }} {{ amount }}</span
        >
      </button>
    </div>

    <CButton @click="subscribe" class="mt-3" align="space" v-if="isActiveCard">
      <span class="card-button__font card-button__font--white">
        {{ $t("general.my-subscription.subscribed-again") }}</span
      >
      <span class="card-button__font card-button__font--white">
        {{ amount }} {{ $t("general.my-subscription.perMonth") }}</span
      >
    </CButton>

    <CButton
      v-if="isUserFailedCard && !isActiveCard"
      @click="subscribe"
      class="mt-3"
      align="space"
      theme="danger"
    >
        <span class="profile-button__font profile-button__font--white">
          {{ $t("general.profile.button.subscriptionFailed") }}</span
        >
      <span class="profile-button__font profile-button__font--white">
          {{ $t("general.profile.button.updateCard") }}</span
      >
    </CButton>

    <div class="subscription-card__footer" v-if="expiration">
      <span class="subscription-card__font subscription-card__font--toggle">
        {{ expiration }}
      </span>
    </div>
  </div>
</template>
<script>
import UiBadge from "@/features/ui/common/UiBadge.vue";
import { mapActions } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import { currencyFormat, formatDateTime } from "@/tools/helpers";
import CButton from "@/features/ui/СButton.vue";
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "SubscriptionCard",
  components: { CIcon, CButton, UiBadge },
  mixins: [waitRequest, validationError],
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isActiveCard() {
      return !this.card.active;
    },
    avatarImage() {
      return this.card?.sub?.avatar || "/mock/PFP.webp";
    },
    info() {
      return this.card?.sub?.username || "some info";
    },
    userId() {
      return this.card.sub.id
    },
    name() {
      return this.card?.sub?.name || "";
    },
    username() {
      return `@${this.card?.sub?.username}` || "";
    },
    amount() {
      if(this.card.active) {
        return `$${this.card?.face_amount || 0}`;
      }

      return `$${this.card?.sub?.face_price || 0}`;
    },
    expiration() {
      if (!this.card?.expires) return "";

      const { date } = this.formatDateTime(this.card?.expires);

      if (!this.isActiveCard) {
        return `${this.$t("general.my-subscription.nextBilling")} ${date}`;
      }

      return `${this.$t("general.my-subscription.ended")} ${date}`;
    },
    isUserFailedCard() {
      return Boolean(this.card?.info?.is_failed);
    },
  },
  methods: {
    currencyFormat,
    formatDateTime,
    ...mapActions({
      setDefaultCards: "subscriptions/setDefaultCards",
      deleteSubscription: "subscriptions/deleteSubscription",
      createSubscription: "subscriptions/createSubscription",
    }),

    subscribe() {
      this.$popup.open("SubscriptionPopup", { card: this.card });
    },

    chat() {},

    unsubscribe() {
      this.$popup.open('UnsubscribePopup', { userId: this.userId, card: this.card } )
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-card {
  border: 1.5px solid $app-gray-25;
  border-radius: 10px;
  width: 100%;
  height: 164px;
  padding: 16px 15px;
  margin-top: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__image-wrapper {
  }

  &__image {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }

  &__description {
    display: flex;
    flex-direction: column;
    margin-left: em(11);
  }

  &__title {
  }

  &__badge {
    margin-top: em(6);
  }

  &__font {
    &--title {
      font-weight: 500;
      font-size: 14px;
      color: $app-black;
      text-transform: uppercase;
    }

    &--number {
      font-weight: 400;
      font-size: 14px;
      color: $app-gray-18;
    }

    &--toggle {
      font-weight: 400;
      font-size: 12px;
      color: $app-gray-18;
    }
  }

  &__badge {
  }

  &__info {
    display: inline-flex;
    align-items: center;
  }

  &__icon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  &__button {
    margin-top: em(16);
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: em(14);
  }

  &__toggle {
    margin-left: em(10);
    font-size: 50%; // change toggle default size
  }

  &__tooltip {
    padding: em(18) em(18) em(20);
  }

  &__button {
    color: $black;
    text-decoration: none;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: em(15);
    }

    &:active {
      transform: none;
      filter: none;
    }
  }
}

.card-button {
  display: flex;
  justify-content: space-between;
  padding: em(14) em(16);
  border: 1.5px solid $app-gray-25;
  border-radius: 100px;

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #308dff;
    text-transform: uppercase;

    &--white {
      color: $white;
    }
  }
}
</style>
